import config from "../config";

const getTicketIndex = async () => {
  const options = {
    base: "https://tickets-middleware",
    params: false,
    payload: false,
    endpoint: `/api/ticket`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getTicketIndexByActiveUser = async () => {
  const options = {
    base: "https://tickets-middleware",
    params: false,
    payload: false,
    endpoint: `/api/ticket/indexByActiveUser`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
}

const getTicketPartial = async () => {
  const options = {
    base: "https://tickets-middleware",
    params: false,
    payload: false,
    endpoint: `/api/ticket/indexByPartial`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getTicketTypeIndex = async () => {
  const options = {
    base: "https://tickets-middleware",
    params: false,
    payload: false,
    endpoint: `/api/ticketType`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const saveTicketType = async (obj) => {
  const options = {
    base: "https://tickets-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/ticketType`,
    method: "POST",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const deleteTicketType = async (id) => {
  const options = {
    base: "https://tickets-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/ticketType/${id}`,
    method: "DELETE",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getTicketOpen = async () => {
  const options = {
    base: "https://tickets-middleware",
    params: false,
    payload: false,
    endpoint: `/api/ticket/indexByOpen`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getTicketCustomer = async () => {
  const options = {
    base: "https://tickets-middleware",
    params: false,
    payload: false,
    endpoint: `/api/ticket/IndexByCustomerUpdated`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getTicketSetup = async () => {
  const options = {
    base: "https://tickets-middleware",
    params: false,
    payload: false,
    endpoint: `/api/ticketSetup`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getTicketRecord = async (id) => {
  const options = {
    base: "https://tickets-middleware",
    params: false,
    payload: false,
    endpoint: `/api/ticket/${id}`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getFiles = async (id) => {
  const options = {
    base: "https://tickets-middleware",
    params: false,
    payload: false,
    endpoint: `/api/file/${id}/download`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getTicketCounts = async () => {
  const options = {
    base: "https://tickets-middleware",
    params: false,
    payload: false,
    endpoint: `/api/ticket/clientIndexCount`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const updateTicket = async (id, obj) => {
  const options = {
    base: "https://tickets-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/ticket/${id}`,
    method: "PUT",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const createTicket = async (obj) => {
  const options = {
    base: "https://tickets-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/ticket`,
    method: "POST",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getAddressesIndex = async (id) => {
  const options = {
    base: "https://tickets-middleware",
    params: false,
    payload: false,
    endpoint: `/api/clientAddressIndex/${id}`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

// const agileTicket = async ( ticket) => {
//   const response = await fetch(`${}/agile/create`, {
//     method: "POST",
//     body: JSON.stringify(ticket),
//     credentials: "include", // fetch won't send cookies unless you set credentials
//     headers: {
//       "Content-Type": "application/json",
//       Accept: "application/json",
//     },
//   });
//   const data = await response.json();
//   return data;
// };

// const stratusSendTicket = async ( ticket) => {
//   const response = await fetch(`${}/stratus/create`, {
//     method: "POST",
//     body: JSON.stringify(ticket),
//     credentials: "include", // fetch won't send cookies unless you set credentials
//     headers: {
//       "Content-Type": "application/json",
//       Accept: "application/json",
//     },
//   });
//   const data = await response.json();

//   return data;
// };

// const stratusCheckTicket = async ( id, current_status, name, description) => {
//   const response = await fetch(`${}/stratus/check?id=${id}`, {
//     method: "POST",
//     body: JSON.stringify({ "ticket_name": name, "description": description, "status": current_status, "update": 1 }),
//     credentials: "include", // fetch won't send cookies unless you set credentials
//     headers: {
//       "Content-Type": "application/json",
//       Accept: "application/json",
//     },
//   });
//   const data = await response.json();

//   return data;
// };

// const externalTicketStore = async ( obj) => {
//   const response = await fetch(`${}/externalTicket/store`, {
//     method: "POST",
//     body: JSON.stringify(obj),
//     credentials: "include", // fetch won't send cookies unless you set credentials
//     headers: {
//       "Content-Type": "application/json",
//       Accept: "application/json",
//     },
//   });
//   const data = await response.json();

//   return data;
// };

const indexPaginate = async (search, page, tag, department) => {
  const options = {
    base: "https://tickets-middleware",
    params: false,
    payload: false,
    endpoint: `/api/ticket/indexPaginate?search=${search}&page=${page}&per_page=25&tag=${tag}&department=${department}`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const reportPaginate = async (obj, page, per_page = 15) => {
  const options = {
    base: "https://tickets-middleware",
    params: false,
    payload: false,
    endpoint: `/api/ticket/indexReportPaginate?date_to=${obj.endDate}&date_from=${obj.startDate}&page=${page}&per_page=${per_page}`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const ticketDocumentIndex = async (payload) => {
  const options = {
    base: "https://tickets-middleware",
    params: payload,
    payload: false,
    endpoint: `/api/ticketDocument`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
}

const ticketDocumentIndexForTicket = async (payload) => {
  const options = {
    base: "https://tickets-middleware",
    params: payload,
    payload: false,
    endpoint: `/api/ticketDocument/indexForTicket`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
}

const ticketDocumentIndexForUser = async (payload) => {
  const options = {
    base: "https://tickets-middleware",
    params: payload,
    payload: false,
    endpoint: `/api/ticketDocument/indexForUser`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
}

const ticketDocumentIndexForRestore = async (payload) => {
  const options = {
    base: "https://tickets-middleware",
    params: payload,
    payload: false,
    endpoint: `/api/ticketDocument/indexForRestore`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
}


const ticketDocumentStore = async (payload) => {
  const options = {
    base: "https://tickets-middleware",
    params: false,
    payload: payload,
    endpoint: `/api/ticketDocument`,
    method: "POST",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
}

const ticketDocumentUpdate = async (id, payload) => {
  const options = {
    base: "https://tickets-middleware",
    params: false,
    payload: payload,
    endpoint: `/api/ticketDocument/${id}`,
    method: "PUT",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
}

const ticketDocumentDelete = async (id) => {
  const options = {
    base: "https://tickets-middleware",
    params: false,
    payload: false,
    endpoint: `/api/ticketDocument/${id}`,
    method: "DELETE",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
}

const ticketDocumentRestore = async () => {
  const options = {
    base: "https://tickets-middleware",
    params: false,
    payload: false,
    endpoint: `/api/ticketDocument/${id}/restore`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
}

export {
  getTicketIndex,
  getTicketIndexByActiveUser,
  getAddressesIndex,
  getTicketTypeIndex,
  saveTicketType,
  deleteTicketType,
  getTicketSetup,
  getTicketCustomer,
  getTicketOpen,
  getTicketPartial,
  getTicketCounts,
  getTicketRecord,
  updateTicket,
  createTicket,
  getFiles,
  indexPaginate,
  reportPaginate,
  ticketDocumentIndex,
  ticketDocumentIndexForTicket,
  ticketDocumentIndexForUser,
  ticketDocumentIndexForRestore,
  ticketDocumentStore,
  ticketDocumentUpdate,
  ticketDocumentDelete,
  ticketDocumentRestore,
};