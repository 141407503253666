import config from "../config";

  const getDocumentsByCustomer = async (id) => {
    console.log("getApplication ID " , id)

    const options = {
        base: "https://portal-middleware",
        params: false,
        payload: false,
        endpoint: `/api/documents/indexByCustomer/${id}`,
        method: "GET"
    };
  
    const data = await fetch(`${config.appURL}/api/proxy`, {
      credentials: "include",
      method: "POST",
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
  
    const res = await data.json();
  
    return res;
  };

  const getTicketByCustomer = async (id) => {
    const options = {
      base: "https://portal-middleware",
      params: false,
      payload: false,
      endpoint: `/api/ticket/indexByCustomer?id=${id}`,
      method: "GET",
    };
  
    const data = await fetch(`${config.appURL}/api/proxy`, {
      credentials: "include",
      method: "POST",
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
  
    const res = await data.json();
  
    return res;
  };

  const getTicketShow = async (id) => {
    const options = {
      base: "https://portal-middleware",
      params: false,
      payload: false,
      endpoint: `/api/ticket/${id}`,
      method: "GET",
    };
  
    const data = await fetch(`${config.appURL}/api/proxy`, {
      credentials: "include",
      method: "POST",
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
  
    const res = await data.json();
  
    return res;
  };

const getProfile = async (id) => {
    const options = {
        base: "https://portal-middleware",
        params: false,
        payload: false,
        endpoint: `/api/agents/${id}`,
        method: "GET"
    }

    const data = await fetch(
        `${config.appURL}/api/proxy`,
        {
            credentials: "include",
            method: 'POST',
            body: JSON.stringify(options),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        }
    );

    const res = await data.json();
    res.ok = data.status == 200;

    return res;
}

const saveImage = async (id, payload) => {
    const options = {
        base: "https://portal-middleware",
        params: false,
        payload: payload,
        endpoint: `/api/agents/setAvatar/${id}`,
        method: "PUT"
    }

    const data = await fetch(
        `${config.appURL}/api/proxy`,
        {
            credentials: "include",
            method: 'POST',
            body: JSON.stringify(options),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        }
    );

    const res = await data.json();
    res.ok = data.status == 200;

    return res;
}

const savePassword = async (id, payload) => {
    const options = {
        base: "https://portal-middleware",
        params: false,
        payload: payload,
        endpoint: `/api/agents/setPassword/${id}`,
        method: "PUT"
    }

    const data = await fetch(
        `${config.appURL}/api/proxy`,
        {
            credentials: "include",
            method: 'POST',
            body: JSON.stringify(options),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        }
    );

    const res = await data.json();
    res.ok = data.status == 200;

    return res;
}

const getAgent = async (agentId) => {
    const options = {
        base: "https://portal-middleware",
        params: false,
        payload: false,
        endpoint: `/api/agents/${agentId}`,
        method: "GET"
    }

    const data = await fetch(
        `${config.appURL}/api/proxy`,
        {
            credentials: "include",
            method: 'POST',
            body: JSON.stringify(options),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        }
    )

    const res = await data.json();
    res.ok = data.status == 200;

    return res;
};

const getAgents = async () => {
    const options = {
        base: "https://portal-middleware",
        params: false,
        payload: false,
        endpoint: `/api/agents`,
        method: "GET"
    }

    const data = await fetch(
        `${config.appURL}/api/proxy`,
        {
            credentials: "include",
            method: 'POST',
            body: JSON.stringify(options),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        }
    );

    const res = await data.json();
    res.ok = data.status == 200;

    return res;
}

const getManagersIndex = async () => {
    const options = {
        base: "https://portal-middleware",
        params: false,
        payload: false,
        endpoint: `/api/agents/managerIndex`,
        method: "GET"
    }

    const data = await fetch(
        `${config.appURL}/api/proxy`,
        {
            credentials: "include",
            method: 'POST',
            body: JSON.stringify(options),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        }
    );

    const res = await data.json();
    res.ok = data.status == 200;

    return res;
}

const getGroupIndex = async () => {
    const options = {
        base: "https://portal-middleware",
        params: false,
        payload: false,
        endpoint: `/api/agents/groupIndex`,
        method: "GET"
    }

    const data = await fetch(
        `${config.appURL}/api/proxy`,
        {
            credentials: "include",
            method: 'POST',
            body: JSON.stringify(options),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        }
    );

    const res = await data.json();
    res.ok = data.status == 200;

    return res;
}

const createUser = async (payload) => {
    const options = {
        base: "https://portal-middleware",
        params: false,
        payload: payload,
        endpoint: `/api/user/register`,
        method: "POST"
    }

    const data = await fetch(
        `${config.appURL}/api/proxy`,
        {
            credentials: "include",
            method: 'POST',
            body: JSON.stringify(options),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        }
    );

    const res = await data.json();
    res.ok = data.status == 200;

    return res;
}

const updateUser = async (agent_id, payload) => {
    const options = {
        base: "https://portal-middleware",
        params: false,
        payload: payload,
        endpoint: `/api/agents/${agent_id}`,
        method: "PUT"
    }

    const data = await fetch(
        `${config.appURL}/api/proxy`,
        {
            credentials: "include",
            method: 'POST',
            body: JSON.stringify(options),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        }
    );

    const res = await data.json();
    res.ok = data.status == 200;

    return res;
}

const lockAccount = async (uuid) => {
    const options = {
        base: "https://portal-middleware",
        params: false,
        payload: {user_uuid: uuid},
        endpoint: `/api/user/lock`,
        method: "PUT"
    }

    const data = await fetch(
        `${config.appURL}/api/proxy`,
        {
            credentials: "include",
            method: 'POST',
            body: JSON.stringify(options),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        }
    );

    const res = await data.json();
    res.ok = data.status == 200;

    return res;
}

const unlockAccount = async (uuid) => {
    const options = {
        base: "https://portal-middleware",
        params: false,
        payload: {user_uuid: uuid},
        endpoint: `/api/user/unlock`,
        method: "PUT"
    }

    const data = await fetch(
        `${config.appURL}/api/proxy`,
        {
            credentials: "include",
            method: 'POST',
            body: JSON.stringify(options),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        }
    );

    const res = await data.json();
    res.ok = data.status == 200;

    return res;
}

const getRegistrations = async (uuid) => {
    const options = {
        base: "https://portal-middleware",
        params: {user: uuid},
        payload: false,
        endpoint: `/api/user/getRegistrations`,
        method: "GET"
    }

    const data = await fetch(
        `${config.appURL}/api/proxy`,
        {
            credentials: "include",
            method: 'POST',
            body: JSON.stringify(options),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        }
    );

    const res = await data.json();
    res.ok = data.status == 200;

    return res;
}

const alterRegistrations = async (payload) => {
    const options = {
        base: "https://portal-middleware",
        params: false,
        payload: payload,
        endpoint: `/api/user/alterRegistrations`,
        method: "PUT"
    }

    const data = await fetch(
        `${config.appURL}/api/proxy`,
        {
            credentials: "include",
            method: 'POST',
            body: JSON.stringify(options),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        }
    );

    const res = await data.json();
    res.ok = data.status == 200;

    return res;
}

const setSignature = async (agentId, payload) => {
    const options = {
        base: "https://portal-middleware",
        params: false,
        payload: payload,
        endpoint: `/api/agents/setSignature/${agentId}`,
        method: "PUT"
    }

    const data = await fetch(
        `${config.appURL}/api/proxy`,
        {
            credentials: "include",
            method: 'POST',
            body: JSON.stringify(options),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        }
    );

    const res = await data.json();
    res.ok = data.status == 200;

    return res;
}

const deleteUser = async (agent_id) => {
    const options = {
        base: "https://tenants-service",
        params: false,
        payload: false,
        endpoint: `/api/users/${agent_id}`,
        method: "DELETE"
    }

    const data = await fetch(
        `${config.appURL}/api/proxy`,
        {
            credentials: "include",
            method: 'POST',
            body: JSON.stringify(options),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        }
    );

    const res = await data.json();
    res.ok = data.status == 200;

    return res;
}

const restoreUser = async (agent_id) => {
    const options = {
        base: "https://tenants-service",
        params: {user: agent_id},
        payload: false,
        endpoint: `/api/users/restore`,
        method: "GET"
    }

    const data = await fetch(
        `${config.appURL}/api/proxy`,
        {
            credentials: "include",
            method: 'POST',
            body: JSON.stringify(options),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        }
    );

    const res = await data.json();
    res.ok = data.status == 200;

    return res;
}

const getApplications = async () => {
    const options = {
        base: "https://tenant-middleware",
        params: false,
        payload: false,
        endpoint: `/api/fusionauth/application`,
        method: "GET"
    }

    const data = await fetch(
        `${config.appURL}/api/proxy`,
        {
            credentials: "include",
            method: 'POST',
            body: JSON.stringify(options),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        }
    );

    const res = await data.json();
    res.ok = data.status == 200;

    return res;
}

const getApplicationsTenant = async () => {
    const options = {
        base: "https://tenant-middleware",
        params: false,
        payload: false,
        endpoint: `/api/fusionauth/application/indexForTenant`,
        method: "GET"
    }

    const data = await fetch(
        `${config.appURL}/api/proxy`,
        {
            credentials: "include",
            method: 'POST',
            body: JSON.stringify(options),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        }
    );

    const res = await data.json();
    res.ok = data.status == 200;

    return res;
}

const getRoles = async (app_uuid) => {
    const options = {
        base: "https://tenants-service",
        params: false,
        payload: false,
        endpoint: `/api/roles/indexForApp/${app_uuid}`,
        method: "GET"
    }

    const data = await fetch(
        `${config.appURL}/api/proxy`,
        {
            credentials: "include",
            method: 'POST',
            body: JSON.stringify(options),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        }
    );

    const res = await data.json();
    res.ok = data.status == 200;

    return res;
}

const getApplication = async (app_uuid) => {
    const options = {
        base: "https://tenant-middleware",
        params: false,
        payload: false,
        endpoint: `/api/fusionauth/application/${app_uuid}`,
        method: "GET"
    }

    const data = await fetch(
        `${config.appURL}/api/proxy`,
        {
            credentials: "include",
            method: 'POST',
            body: JSON.stringify(options),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        }
    );

    const res = await data.json();
    res.ok = data.status == 200;

    return res;
}

const updateApplication = async (app_uuid, payload) => {
    const options = {
        base: "https://tenant-middleware",
        params: false,
        payload: payload,
        endpoint: `/api/fusionauth/application/${app_uuid}`,
        method: "PUT"
    }

    const data = await fetch(
        `${config.appURL}/api/proxy`,
        {
            credentials: "include",
            method: 'POST',
            body: JSON.stringify(options),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        }
    );

    const res = await data.json();
    res.ok = data.status == 200;

    return res;
}

const contactUpdate = async (id , payload) => {
    const options = {
      base: "https://portal-middleware",
      params: false,
      payload: payload,
      endpoint: `/api/contactUpdate/${id}`,
      method: "PUT",
    };
  
    const data = await fetch(`${config.appURL}/api/proxy`, {
      credentials: "include",
      method: "POST",
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
  
    const res = await data.json();
    res.ok = data.status == 200;
  
    return res;
  }

  const getLink = async (id) => {
    const options = {
      base: "https://portal-middleware",
      params: false,
      payload: false,
      endpoint: `/api/documents/${id}/getLink`,
      method: "GET",
    };
  
    const data = await fetch(`${config.appURL}/api/proxy`, {
      credentials: "include",
      method: "POST",
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
  
    const res = await data.json();
  
    return res;
  };

export {
    getProfile,
    saveImage,
    savePassword,
    getAgent,
    getAgents,
    getManagersIndex,
    getGroupIndex,
    createUser,
    updateUser,
    lockAccount,
    unlockAccount,
    getRegistrations,
    alterRegistrations,
    setSignature,
    deleteUser,
    restoreUser,
    getApplication,
    getApplications,
    getApplicationsTenant,
    updateApplication,
    getRoles,
    getDocumentsByCustomer, 
    getTicketByCustomer, 
    getTicketShow, 
    contactUpdate,
    getLink
}
